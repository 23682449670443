<template>
    <div>
        <div class="row-start-2 lg:row-start-1">
            <slot name="subtitle">
                <UIHeading tag="h1" role="subtitle" class="mb-2.5 md:mb-4">
                    <slot name="subtitle-text"></slot>
                </UIHeading>
            </slot>

            <slot name="title">
                <UIHeading tag="h2" class="mb-2.5">
                    <slot name="title-text"></slot>
                </UIHeading>
            </slot>

            <div class="text-lg text-slate-800 font-semibold">
                <slot name="head-text"></slot>
            </div>
        </div>

        <div class="grid lg:grid-cols-[288px_1fr] gap-9 lg:gap-10 mt-10">
            <div>
                <slot name="aside"></slot>
            </div>
            <div>
                <slot name="main"></slot>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
</script>
